import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const DropdownCalendar = ({ label, value, onChange, error }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const calendarRef = useRef();

  useEffect(() => {
    if (value instanceof Date) {
      setSelectedDate(value);
    }
  }, [value]);

  const handleInputChange = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col px-6 py-1 w-auto">
      <label
        htmlFor="dropdown-calendar"
        className="mb-1 text-sm font-light uppercase"
      >
        {label}
      </label>
      <div ref={calendarRef} className="relative">
        <input
          id="dropdown-calendar"
          type="text"
          value={
            selectedDate ? selectedDate.toLocaleDateString() : "DD MM YYYY"
          }
          onChange={handleInputChange}
          onClick={() => setIsDropdownOpen(true)}
          className={`px-3 py-1 border rounded-sm focus:outline-none focus:ring-1 focus:border-blue-500 text-sm cursor-pointer w-full ${
            error ? "border-red-500" : ""
          }`}
        />
        {isDropdownOpen && (
          <div className="absolute z-10 top-full mt-1">
            <div className="w-10/12">
              <Calendar
                onChange={handleDateChange}
                value={selectedDate}
                className="border rounded-sm bg-white shadow-md"
              />
            </div>
          </div>
        )}
      </div>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default DropdownCalendar;
