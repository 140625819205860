import React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

const Drawer = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="w-1/2 bg-white shadow-lg h-screen absolute top-0 right-0 z-50 transition-transform transform overflow-hidden">
          <div
            className="absolute top-2 right-2 cursor-pointer "
            onClick={onClose}
          >
            <IoCloseCircleOutline className="h-10 w-10 text-yellow-500 hover:text-yellow-700" />
          </div>
          {children}
        </div>
      )}
    </>
  );
};

export default Drawer;
