import React, { useEffect, useState, useRef } from "react";
import CardItem from "./CardItem";
import { FaUser, FaUsers, FaCalendar, FaTasks, FaEye, FaClock, FaEllipsisV } from "react-icons/fa";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : process.env.REACT_APP_API_BASE_URL;

const iconMap = {
  FaUser: <FaUser />,
  FaUsers: <FaUsers />,
  FaCalendar: <FaCalendar />,
  FaTasks: <FaTasks />,
  FaClock: <FaClock />,
};

const Card = ({ 
  title, 
  content, 
  workflowOwner, 
  collaboratedBy, 
  dueDate, 
  progress, 
  task, 
  currentStepOwner, 
  workflowId, 
  frequency,
  interval,
  onDelete
}) => {
  const [cardItems, setCardItems] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const handleViewClick = () => {
    navigate(`/workflow/${workflowId}`);
  };

  const handleEditClick = () => {
    console.log("Edit clicked");
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/workflows/${workflowId}`);

        if (response.status === 200 || response.status === 204) {
            toast.success("Workflow deleted successfully.", {
                autoClose: 1000,
                onClose: () => {
                    // Call the onDelete prop to refresh the workflows and remove the deleted card
                    onDelete(workflowId);
                }
            });
        } else {
            toast.error("Failed to delete workflow. Please try again.");
        }
    } catch (error) {
        console.error('Error deleting workflow:', error);
        toast.error("An error occurred while deleting the workflow.");
    } finally {
        setDeleteDialogOpen(false);
        setShowMenu(false);
    }
};

  

  const cancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    const items = [
      {
        id: 1,
        title: "Owner",
        content: `User ${workflowOwner}`,
        icon: iconMap.FaUser,
      },
      {
        id: 2,
        title: "Collaborated by",
        content: `User ${collaboratedBy}`,
        icon: iconMap.FaUsers,
      },
      {
        id: 3,
        title: "Due",
        content: dueDate,
        icon: iconMap.FaCalendar,
      },
      {
        id: 4,
        title: "Task",
        content: task,
        icon: iconMap.FaTasks,
      },
      {
        id: 5,
        title: "Progress",
        content: content,
        stepOwner: `User ${currentStepOwner}`,
        icon: iconMap.FaClock,
        isProgress: true,
        progressValue: parseInt(progress, 10),
        showMessageIcon: true,
        isCompleted: progress === "100%",
        task: task 
      },
      {
        id: 6,
        title: "Frequency",
        content: frequency,
        icon: iconMap.FaClock, 
      }
    ];
    setCardItems(items);
  }, [workflowOwner, collaboratedBy, dueDate, progress, task, content, currentStepOwner, frequency, interval]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative max-w-md bg-white rounded-md overflow-hidden shadow-xl p-4 transition-transform duration-300 transform hover:scale-105 hover:shadow-lg">
      <div className="flex justify-between items-center p-3">
        <div className="text-xl">{title}</div>
        <div className="flex items-center gap-2 relative">
          <button
            onClick={handleViewClick}
            className="view-button w-auto bg-button-secondary bg-opacity-80 text-white rounded-lg p-1 text-sm cursor-pointer flex items-center gap-1 shadow-lg transition duration-300 transform hover:bg-secondary-color hover:text-primary-color hover:translate-y-1 hover:shadow-2xl"
          >
            <FaEye />
            View
          </button>
          <div className="relative" ref={menuRef}>
            <FaEllipsisV 
              className="text-gray-600 cursor-pointer"
              onClick={() => setShowMenu(!showMenu)}
            />
            {showMenu && (
              <div className="absolute right-0 mt-2 w-auto bg-white shadow-lg rounded-lg border border-gray-200 z-50 text-sm">
                <button
                  onClick={handleEditClick}
                  className="w-full text-left px-4 py-2 hover:bg-gray-50"
                >
                  Edit
                </button>
                <div className="border-t border-button"></div> {/* Yellow separator line */}
                <button
                  onClick={handleDeleteClick}
                  className="w-full text-left px-4 py-2 text-red-500 hover:bg-red-100"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        {cardItems.map((item) => (
          <div key={item.id} className="w-1/2 text-base font-thin">
            <CardItem
              title={item.title}
              content={item.content}
              stepOwner={item.stepOwner}
              icon={item.icon}
              isProgress={item.isProgress}
              progressValue={item.progressValue}
              showMessageIcon={item.showMessageIcon}
              isCompleted={item.isCompleted}
              intervalContent={item.title === "Frequency" && frequency === "recurrent" ? `Interval: ${interval}` : ""}
              frequency={item.title === "Frequency" ? frequency : ""}
              task={item.task}
            />
          </div>
        ))}
      </div>
      <Dialog
        open={deleteDialogOpen}
        onClose={cancelDelete}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this workflow? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            color="error" // Set button color to red
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Card;
