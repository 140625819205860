import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "./common/Loader";
import routes from "./routes";
import { ToastContainer } from 'react-toastify'; // Import ToastContainer

const DefaultLayout = lazy(() => import("./layout/DefaultLayout"));
const Login = lazy(() => import("./pages/Login"));

// Define the main App component
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            }
          />
          {/* DefaultLayout wraps all the routes */}
          <Route element={<DefaultLayout />}>
            {routes.map((routes, index) => {
              // Destructuring path and component from each route
              const { path, component: Component } = routes;
              return (
                // Each route is a Route component
                <Route
                  key={index}
                  path={path}
                  element={
                    // Using Suspense for lazy loading, fallback to Loader component while loading
                    <Suspense fallback={<Loader />}>
                      <Component />
                      {/* Render the component for the route */}
                    </Suspense>
                  }
                />
              );
            })}
          </Route>
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
        <ToastContainer /> {/* Add ToastContainer here */}
      </BrowserRouter>
    </div>
  );
}

export default App;
