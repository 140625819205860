import React, { useState } from "react";
import TextField from "../components/TextFields";
import Dropdown from "../components/Dropdown";
import CalendarComponent from "../components/Calender";
import { IoMdAdd } from "react-icons/io";

const AddEmail = ({ onAddSelectClick, stepIndex, formData }) => {
  const [taskTitle, setTasktitle] = useState(
    formData?.values?.steps[stepIndex]?.task?.title || ""
  );
  const [taskOwner, setTaskOwner] = useState(
    formData?.values?.steps[stepIndex]?.task?.owner || ""
  );
  const [taskDuration, setTaskDuration] = useState(
    formData?.values?.steps[stepIndex]?.task?.duration || ""
  );


  const handleAddButtonClick = () => {
    const taskData = {
      title: taskTitle,
      owner: taskOwner,
      duration: taskDuration,
      asignee: "",
      due: "",
    };
    onAddSelectClick(taskData);
  };

  return (
    <div>
      <div className="px-4 font-medium text-xl py-10">Add Task</div>
      <div className="grid grid-cols-2 gap-4 w-full p-4">
        <TextField
          label={"Task Title"}
          value={taskTitle}
          onChange={(value) => setTasktitle(value)}
        />
        <Dropdown
          label="Task Owner"
          options={[
            { label: "VP", value: "VP" },
            { label: "Opz Lead", value: "Opz Lead" },
          ]}
          value={taskOwner}
          onChange={(taskOwner) => setTaskOwner(taskOwner)}
        />
        <CalendarComponent
          label={"Duration"}
          name="TaskDuration"
          value={taskDuration}
          onChange={(taskDuration) => setTaskDuration(taskDuration)}
        />
        
        <div
          className={`col-span-2 flex justify-center items-center gap-1 py-1 bg-blue-500 rounded-sm text-white text-sm ml-6 text-center cursor-pointer w-32 
           `}
          onClick={handleAddButtonClick}
        >
          <IoMdAdd />
          Add
        </div>
      </div>
    </div>
  );
};

export default AddEmail;
