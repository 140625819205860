import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, Chip, Box } from "@mui/material";
import { IoMdClose } from "react-icons/io";

const Addprecondition = ({ onAddButtonClick, stepIndex, formData }) => {
  const [selectedSteps, setSelectedSteps] = useState([]);

  useEffect(() => {
    // Initialize selectedSteps with existing preconditions
    if (formData.values.steps[stepIndex].preconditions.stepId) {
      setSelectedSteps(formData.values.steps[stepIndex].preconditions.stepId.split(',').map(Number));
    }
  }, [formData.values.steps, stepIndex]);

  const handleStepSelection = (event) => {
    setSelectedSteps(event.target.value);
  };

  const handleAddButtonClick = () => {
    const stepId = selectedSteps.join(',');
    onAddButtonClick({ stepId });
  };

  const handleDeleteStep = (stepToDelete) => {
    setSelectedSteps(selectedSteps.filter(step => step !== stepToDelete));
  };

  return (
    <div>
      <div className="px-4 font-medium text-xl py-10">Add Precondition</div>
      <div className="grid grid-cols-1 gap-4 w-full p-4">
        <FormControl fullWidth>
          <InputLabel id="step-select-label">Select Steps</InputLabel>
          <Select
            labelId="step-select-label"
            id="step-select"
            multiple
            value={selectedSteps}
            onChange={handleStepSelection}
            input={<OutlinedInput label="Select Steps" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={formData.values.steps.find(step => step.step === value).name}
                    onDelete={(event) => {
                      event.stopPropagation();
                      handleDeleteStep(value);
                    }}
                    deleteIcon={
                      <IoMdClose
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    }
                  />
                ))}
              </Box>
            )}
          >
            {formData.values.steps.map((step) => (
              step.step !== stepIndex + 1 && (
                <MenuItem key={step.step} value={step.step}>
                  {step.name}
                </MenuItem>
              )
            ))}
          </Select>
        </FormControl>
        <div className="flex justify-start">
          <div
            className="flex justify-center items-center gap-1 py-2 px-4 bg-button-color rounded-sm text-white text-sm hover:bg-button-secondary hover:text-primary-color text-center cursor-pointer"
            onClick={handleAddButtonClick}
          >
            Add Precondition
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addprecondition;