import React, { useState, useEffect } from "react";
import { IoIosArrowBack,IoIosHome } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import {
  Modal,
  Box,
  Typography,
  Button,
  Backdrop,
  TextField,
  MenuItem,
  IconButton,
  Checkbox,
  ListItemText,
  Select,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  createNodes,
  createEdges,
  getColorByStatus,
  areParentsFinished,
} from "./flowData";
import Flow from "./flow";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : process.env.REACT_APP_API_BASE_URL;

const Workflow = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { id: workflowId } = params;

  const [selectedNode, setSelectedNode] = useState(null);
  const [open, setOpen] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [preconditions, setPreconditions] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [workflowData, setWorkflowData] = useState(null);

  useEffect(() => {
    if (workflowId) {
      const fetchWorkflowData = async () => {
        try {
          const response = await axios.get(
            `${BASE_URL}/api/workflows/${workflowId}`
          );
          setWorkflowData(response.data);
        } catch (error) {
          toast.error("Failed to load workflow. Please try again.");
        }
      };

      fetchWorkflowData();
    }
  }, [workflowId]);

  const handleNodeClick = (event, node) => {
    setSelectedNode(node.data);
    setOpen(true);
    const parentIds = node.data.parent_id || [];
    const parentEvents = parentIds
      .map((id) => workflowData?.steps?.find((step) => step.id === id))
      .filter((step) => step);
    setPreconditions(parentEvents.map((step) => step.id.toString()));
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedNode(null);
    setPreconditions([]);
    setEditMode(false);
  };

  const handleStatusChange = async () => {
    if (selectedNode) {
      if (selectedNode.status === "finished") {
        toast.warning("Cannot change status. This node is already finished.");
        return;
      }

      if (!areParentsFinished(selectedNode.id, workflowData.steps)) {
        toast.warning("Cannot change status. All parent nodes must be finished.");
        return;
      }

      try {
        const response = await axios.put(
          `${BASE_URL}/api/steps/${selectedNode.id}`,
          {
            status: newStatus,
          }
        );

        if (response.data.success) {
          const updatedSteps = workflowData.steps.map((step) =>
            step.id === selectedNode.id ? { ...step, status: newStatus } : step
          );
          setWorkflowData({ ...workflowData, steps: updatedSteps });
          setOpen(false);
          setSelectedNode(null);
          setNewStatus("");
          toast.success("Status updated successfully.");
        } else {
          toast.error("Failed to update status. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred while updating status.");
      }
    }
  };

  const handlePreconditionChange = (event) => {
    const {
      target: { value },
    } = event;
    setPreconditions(typeof value === "string" ? value.split(",") : value);
  };

  const handleUpdatePreconditions = async () => {
    if (selectedNode) {
      try {
        const response = await axios.put(
          `${BASE_URL}/api/steps/${selectedNode.id}/preconditions`,
          {
            preconditions: preconditions.map((id) => parseInt(id, 10)),
          }
        );

        if (response.data.success) {
          const updatedSteps = workflowData.steps.map((step) =>
            step.id === selectedNode.id
              ? { ...step, parent_id: preconditions.map((id) => parseInt(id, 10)) }
              : step
          );
          setWorkflowData({ ...workflowData, steps: updatedSteps });
          setOpen(false);
          setSelectedNode(null);
          setNewStatus("");
          setPreconditions([]);
          toast.success("Preconditions updated successfully.");
        } else {
          toast.error("Failed to update preconditions. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred while updating preconditions.");
      }
    }
  };

  const handleDeletePrecondition = (preconditionId) => {
    setPreconditions(preconditions.filter((id) => id !== preconditionId));
  };

  if (!workflowData) {
    return <div>Loading...</div>;
  }

  const nodes = workflowData ? createNodes(workflowData.steps) : [];
  const edges = workflowData ? createEdges(workflowData.steps) : [];

  return (
    <div className="min-h-screen bg-main-bg px-2 pt-2">
      <ToastContainer />
      <div className="flex justify-between w-full h-8 bg-white rounded-md items-center mt-1 px-3">
        <div
          className="p-2 cursor-pointer flex gap-2 items-center text-sm text-gray-600 hover:text-black"
          onClick={() => navigate("/home")}
        >
          <IoIosArrowBack className="w-4 h-4" />
          Back
        </div>
        
        <div
            className="flex items-center text-gray-600 hover:text-black text-xl px-4 py-2 rounded cursor-pointer "
            onClick={() => navigate("/home")}
          >
            <IoIosHome className="mr-2" />
            
          </div>
          
      </div>
      <div className="px-4 pt-4 font-medium text-xl mb-6">
          {workflowData?.workflowName || "Unnamed Workflow"}
        </div>
      <div className="bg-white rounded-lg shadow-md p-4">
        <Flow edges={edges} nodes={nodes} onNodeClick={handleNodeClick} />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          sx: {
            backgroundColor: selectedNode
              ? `${getColorByStatus(selectedNode.status)}20`
              : "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Box
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-md p-4 rounded-lg"
          sx={{
            maxWidth: "80vw",
            width: "400px",
            outline: "none",
          }}
        >
          <Box className="flex justify-between items-center">
            <Typography variant="h6" component="h2" gutterBottom>
              {selectedNode?.event}
            </Typography>
            <Box className="flex items-center">
              <Tooltip
                title={editMode ? "Edit Status" : "Edit Preconditions"}
              >
                <IconButton
                  onClick={() => setEditMode(!editMode)}
                  className="mr-2"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="body1" gutterBottom>
            Status: {selectedNode?.status}
          </Typography>
          <Typography
            variant="body2"
            className="text-gray-600"
            gutterBottom
          >
            Step Owner: {selectedNode?.stepOwner}
          </Typography>
          <Typography
            variant="body2"
            className="text-gray-600"
            gutterBottom
          >
            Date: {selectedNode?.date}
          </Typography>
          <Typography
            variant="body2"
            className="text-gray-600"
            gutterBottom
          >
            Due Date: {selectedNode?.dueDate}
          </Typography>
          {selectedNode?.status !== "finished" && (
            <>
              {!editMode && (
                <>
                  <TextField
                    select
                    label="New Status"
                    value={newStatus}
                    onChange={(e) => setNewStatus(e.target.value)}
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="finished">Finished</MenuItem>
                    <MenuItem value="inProgress">In Progress</MenuItem>
                    <MenuItem value="notStarted">Not Started</MenuItem>
                  </TextField>
                  <Button
                    onClick={handleStatusChange}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="mt-2"
                    sx={{
                      bgcolor: getColorByStatus(newStatus),
                      "&:hover": {
                        bgcolor: getColorByStatus(newStatus),
                      },
                    }}
                  >
                    Change Status
                  </Button>
                </>
              )}
              {editMode && (
                <>
                  <Typography
                    variant="body4"
                    className="text-gray-600 font-bold mt-10"
                    gutterBottom
                  >
                    Current Preconditions:
                  </Typography>
                  {preconditions.length > 0 ? (
                    preconditions.map((preconditionId) => {
                      const preconditionEvent = workflowData.steps.find(
                        (event) => event.id.toString() === preconditionId
                      );
                      return (
                        <Box
                          key={preconditionId}
                          className="flex items-center justify-between my-1"
                        >
                          <Typography
                            variant="body2"
                            className="text-blue-600"
                          >
                            {preconditionEvent?.event}
                          </Typography>
                          <IconButton
                            onClick={() => handleDeletePrecondition(preconditionId)}
                            size="small"
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography variant="body2" className="text-blue-600">
                      No Preconditions
                    </Typography>
                  )}
                  <Select
                    multiple
                    value={preconditions}
                    onChange={handlePreconditionChange}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (id) =>
                            workflowData.steps.find(
                              (event) => event.id.toString() === id
                            )?.event
                        )
                        .join(", ")
                    }
                    fullWidth
                    margin="normal"
                    className="bg-indigo-100 "
                  >
                    {workflowData.steps.map((event) => (
                      <MenuItem
                        key={event.id}
                        value={event.id.toString()}
                      >
                        <Checkbox
                          checked={preconditions.includes(
                            event.id.toString()
                          )}
                        />
                        <ListItemText primary={event.event} />
                      </MenuItem>
                    ))}
                  </Select>
                  <Button
                    onClick={handleUpdatePreconditions}
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      bgcolor: "gray",
                      "&:hover": {
                        bgcolor: "#023047",
                        color:"#facc11",
                      },
                    }}
                  >
                    Update Preconditions
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Workflow;
