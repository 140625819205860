import React from "react";
import { IoMdAdd } from "react-icons/io";

const SelectButton = ({ label, onClick, addedTask, addedProject, addedEmail }) => {
  return (
    <div className="flex gap-10 items-center px-6 py-1 ">
      <label htmlFor="textfield" className="text-sm font-light uppercase">
        Step type
      </label>
      <div className="grid grid-cols-3 w-3/4 gap-3">
        <div
          className={`flex items-center justify-start text-sm px-8 py-1 rounded-sm cursor-pointer 
           ${addedTask ? "bg-green-700 text-white" : "bg-white text-yellow-500 hover:text-green-700"}
          `}
          onClick={() => onClick("task")}
        >
          <IoMdAdd className="mr-1" />
          Task
        </div>
        <div
          className={`flex items-center justify-start text-sm px-8 py-1 rounded-sm cursor-pointer 
           ${addedProject ? "bg-green-700 text-white" : "bg-white text-yellow-500 hover:text-green-700"}
          `}
          onClick={() => onClick("project")}
        >
          <IoMdAdd className="mr-1" />
          Project
        </div>
        <div
          className={`flex items-center justify-start text-sm px-8 py-1 rounded-sm cursor-pointer 
           ${addedEmail ? "bg-green-700 text-white" : "bg-white text-yellow-500 hover:text-green-700"}
          `}
          onClick={() => onClick("email")}
        >
          <IoMdAdd className="mr-1" />
          Email
        </div>
      </div>
    </div>
  );
};

export default SelectButton;
