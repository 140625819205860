import Home from "../pages/Home";
import Createworkflow from "../pages/createworkflow/Createworkflow";
import Workflow from "../pages/workflow/Workflow";

const coreRoutes = [
  {
    path: "/home",
    title: "Home",
    component: Home,
  },
  {
    path: "/createworkflow",
    title: "Createworkflow",
    component: Createworkflow,
  },{
    path: "/workflow/:id", 
    title: "Workflow",
    component: Workflow,
  },
];

const routes = [...coreRoutes];
export default routes;
