import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaEnvelope, FaChevronRight, FaCheckCircle } from "react-icons/fa";

const CardItem = ({
  title,
  content,
  icon,
  isProgress,
  progressValue,
  showMessageIcon,
  stepOwner,
  isCompleted,
  intervalContent,
  frequency
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle expanded state on click
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="relative flex flex-col gap-4 p-3">
      <div className="flex items-center gap-4">
        <div className="bg-yellow-400 rounded-sm flex items-center justify-center w-6 h-6 text-white">
          {icon}
        </div>
        <div className="flex flex-col justify-center items-start flex-1 relative">
          <div className="text-sm text-gray-400 font-light">{title}</div>
          {isProgress ? (
            <>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mt-1 relative overflow-hidden">
                <div
                  className={`h-.5 absolute top-0 left-0 transition-all duration-300 ${isCompleted ? 'bg-green-600' : 'bg-secondary-color'}`}
                  style={{ width: `${progressValue}%` }}
                />
              </div>
              <div className="flex items-center mt-1">
                {isCompleted && (
                  <div className="flex text-xs items-center text-green-600">
                    <FaCheckCircle className="mr-1" />
                    <span>Completed</span>
                  </div>
                )}
              </div>

              {/* Show Details Button */}
              <div
                className="text-sm text-blue-500 flex items-center cursor-pointer mt-1 relative"
                onClick={toggleExpanded}
              >
                Show Details
                <FaChevronRight className="pl-1" />

                {/* Expanded Section */}
                <div
                  className={`absolute transition-all duration-300 ease-in-out bg-gray-50 rounded-md shadow-inner ${
                    isExpanded ? 'opacity-100 z-20 w-48 h-30 p-4' : 'opacity-0 z-[-1] h-0 w-0 p-0'
                  }`}
                  style={{ right: isExpanded ? '-200px' : '0', top: isExpanded ? '30px' : '0', transform: isExpanded ? 'translateY(-100%)' : 'translateY(0)' }}
                >
                  {isExpanded && (
                    <div className="space-y-1.5">
                      <div className="text-sm font-bold text-gray-700">Current Step</div>
                      <div className="text-sm text-gray-600">{content}</div>
                      <div className="h-px bg-yellow-400 my-2"></div>
                      <div className="text-sm font-bold text-gray-700">Step Owner</div>
                      <div className="text-sm text-gray-600 flex items-center">
                        <a href={`mailto:${stepOwner}@example.com`} className="text-blue-500 flex items-center">
                          {stepOwner}
                          <FaEnvelope className="ml-1" />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="font-light text-base flex items-center">
              {content}
              {showMessageIcon && (
                <FaEnvelope className="ml-2 text-blue-500 cursor-pointer" />
              )}
            </div>
          )}

          {/* Display only interval if frequency is recurrent */}
          {title === "Frequency" && frequency === "recurrent" && intervalContent && (
            <div className="">
              <div className="text-sm text-gray-600 ">
                {intervalContent} 
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  isProgress: PropTypes.bool,
  progressValue: PropTypes.number,
  showMessageIcon: PropTypes.bool,
  stepOwner: PropTypes.string,
  isCompleted: PropTypes.bool,
  intervalContent: PropTypes.string,
  frequency: PropTypes.string,
};

CardItem.defaultProps = {
  isProgress: false,
  progressValue: 0,
  showMessageIcon: false,
  stepOwner: "",
  isCompleted: false,
  intervalContent: "",
  frequency: ""
};

export default CardItem;
