import React from "react";

const Dropdown = ({ label, options, onChange, error,value }) => {
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <div className="flex flex-col px-6 py-1 w-full relative">
      <label htmlFor="dropdown" className="mb-1 text-sm font-light uppercase">
        {label}
      </label>
      <select
        id="dropdown"
        className={`px-3 py-1 border rounded-sm focus:outline-none focus:border-blue-500 text-sm appearance-none bg-white ${
          error ? "border-red-500" : ""
        }`}
        onChange={handleChange}
        defaultValue={value}
      >
        <option value="" hidden>
          Select
        </option>
        {options.map((option, index) => (
          <option key={index} value={option?.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
};

export default Dropdown;
