import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "../../components/TextFields";
import Dropdown from "../../components/Dropdown";
import Drawer from "../../components/Drawer";
import AddTask from "../AddTask";
import AddProject from "../AddProject";
import Addprecondition from "../Addprecondition";
import AddEmail from "../AddEmail"; 
import { IoMdAdd,IoIosHome } from "react-icons/io";
import AddButton from "../../components/AddButton";
import SelectButton from "../../components/SelectButton";
import CalendarComponent from "../../components/Calender";
import { MdDeleteForever } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : process.env.REACT_APP_API_BASE_URL;
    
// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  workflowName: Yup.string().required("Workflow name is required"),
  workflowOwner: Yup.string().required("Workflow owner is required"),
  collaboratedBy: Yup.string().required("Collaborator is required"),
  workflowType: Yup.string().required("Workflow type is required"),
  workflowDue: Yup.date().required("Workflow due date is required"),
  frequency: Yup.string().required("Frequency is required"),
  interval: Yup.string().when("frequency", (frequency, schema) => {
    return frequency === "recurrent"
      ? schema.required("Interval is required")
      : schema;
  }),
  steps: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Step name is required"),
      stepOwner: Yup.string().required("Step owner is required"),
      due_date: Yup.date().required("Step due date is required"),
    })
  ),
});

const CreateWorkflow = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);

  // Initialize useFormik hook for form handling
  const formik = useFormik({
    initialValues: {
      workflowName: "",
      workflowOwner: "",
      collaboratedBy: "",
      workflowType: "",
      workflowDue: "",
      frequency: "",
      interval: "",
      steps: [
        {
          step: 1,
          name: "",
          status: "notStarted",
          stepOwner: "",
          due_date: "",
          preconditions: {
            stepId: ""
          },
          task: {},
          project: {},
          email: {},
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${BASE_URL}/api/workflows`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: values.workflowName,
            owner_id: parseInt(values.workflowOwner, 10),
            collaborated_by: parseInt(values.collaboratedBy, 10),
            due_date: values.workflowDue,
            workflow_type_id: parseInt(values.workflowType, 10),
            frequency: values.frequency,
            interval: values.interval ? parseInt(values.interval, 10) : null,
            steps: values.steps.map(step => ({
              name: step.name,
              status: step.status,
              stepOwner: parseInt(step.stepOwner, 10),
              due_date: step.due_date,
              preconditions: step.preconditions,
              step: step.step
            }))
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to create workflow');
        }

        const result = await response.json();
        console.log('Workflow created:', result);
        toast.success("Workflow created successfully!", {
          position: "top-right",
          autoClose:2000,
        });
        navigate('/createworkflow');  // Adjust this path as needed
      } catch (error) {
        console.error('Error creating workflow:', error);
        toast.error("Error creating workflow!", {
          position: "top-right",
        });
        
      }
    },
  });

  const handleAddButtonClick = (value, stepIndex) => {
    if (value === "precondition") {
      setDrawerContent(
        <Addprecondition
          onAddButtonClick={(preconditionDetails) =>
            handleAddPrecondition(preconditionDetails, stepIndex)
          }
          formData={formik}
          stepIndex={stepIndex}
        />
      );
    }
    setIsDrawerOpen(true);
  };

  const handleSelectButtonClick = (value, stepIndex) => {
    if (value === "task") {
      setDrawerContent(
        <AddTask
          onAddSelectClick={(taskDetails) =>
            handleAddTask(taskDetails, stepIndex)
          }
          formData={formik}
          stepIndex={stepIndex}
        />
      );
    } else if (value === "project") {
      setDrawerContent(
        <AddProject
          onAddSelectClick={(projectDetails) =>
            handleAddProject(projectDetails, stepIndex)
          }
          formData={formik}
          stepIndex={stepIndex}
        />
      );
    } else if (value === "email") {
      setDrawerContent(
        <AddEmail
          onAddSelectClick={(emailDetails) =>
            handleAddEmail(emailDetails, stepIndex)
          }
          formData={formik}
          stepIndex={stepIndex}
        />
      );
    }
    setIsDrawerOpen(true);
  };

  const handleAddPrecondition = (preconditionDetails, stepIndex) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      steps: prevValues.steps.map((step, index) =>
        index === stepIndex
          ? { ...step, preconditions: preconditionDetails }
          : step
      ),
    }));
    setIsDrawerOpen(false);
    setDrawerContent(null);
  };

  const handleAddTask = (taskDetails, stepIndex) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      steps: prevValues.steps.map((step, index) =>
        index === stepIndex ? { ...step, task: taskDetails } : step
      ),
    }));
    setIsDrawerOpen(false);
    setDrawerContent(null);
  };

  const handleAddProject = (projectDetails, stepIndex) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      steps: prevValues.steps.map((step, index) =>
        index === stepIndex ? { ...step, project: projectDetails } : step
      ),
    }));
    setIsDrawerOpen(false);
    setDrawerContent(null);
  };

  const handleAddEmail = (emailDetails, stepIndex) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      steps: prevValues.steps.map((step, index) =>
        index === stepIndex ? { ...step, email: emailDetails } : step
      ),
    }));
    setIsDrawerOpen(false);
    setDrawerContent(null);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setDrawerContent(null);
  };

  const handleStepChange = (index, name, value) => {
    const newSteps = formik.values.steps.map((step, i) =>
      i === index ? { ...step, [name]: value } : step
    );
    formik.setFieldValue("steps", newSteps);
  };

  const handleAddStep = () => {
    const newSteps = [
      ...formik.values.steps,
      {
        step: formik.values.steps.length + 1,
        name: "",
        status: "notStarted",
        stepOwner: "",
        due_date: "",
        preconditions: {
          stepId: ""
        },
        task: {},
        project: {},
        email: {},
      },
    ];
    formik.setFieldValue("steps", newSteps);
  };

  const handleDeleteStep = (index) => {
    const newSteps = formik.values.steps.filter((_, i) => i !== index)
      .map((step, i) => ({ ...step, step: i + 1 }));
    formik.setFieldValue("steps", newSteps);
  };

  return (
    <div className="bg-main-bg h-full">
    <div className="sticky top-1 bg-main-bg px-2 pt-2 z-10">
    <div className="flex justify-between w-full h-8 bg-white rounded-md items-center px-3">
        <div
          className="p-2 cursor-pointer flex gap-2 items-center text-sm text-gray-600 hover:text-black"
          onClick={() => navigate("/home")}
        >
          <IoIosArrowBack className="w-4 h-4" />
          Back
        </div>
        
        <div
className="flex items-center text-gray-600 hover:text-black text-xl px-4 py-2 rounded cursor-pointer "            onClick={() => navigate("/home")}
          >
            <IoIosHome className="mr-2" />
            
          </div>
      </div>
      <div className="px-4 pt-4 font-medium text-xl  mb-6">Create Workflow</div>
      <div className="bg-white rounded-md shadow-md p-4">
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <TextField
            label="Workflow Name"
            value={formik.values.workflowName}
            onChange={(value) => formik.setFieldValue("workflowName", value)}
            error={formik.touched.workflowName && formik.errors.workflowName}
          />
          <Dropdown
            label="Workflow Owner"
            options={[
              { label: "Akshay", value: "1" },
              { label: "Vivek", value: "2" },
              { label: "Amit", value: "3" },
            ]}
            value={formik.values.workflowOwner}
            onChange={(value) => formik.setFieldValue("workflowOwner", value)}
            error={formik.touched.workflowOwner && formik.errors.workflowOwner}
          />
          <Dropdown
            label="Collaborated By"
            options={[
              { label: "Akshay", value: "1" },
              { label: "Vivek", value: "2" },
              { label: "Amit", value: "3" },
            ]}
            value={formik.values.collaboratedBy}
            onChange={(value) => formik.setFieldValue("collaboratedBy", value)}
            error={
              formik.touched.collaboratedBy && formik.errors.collaboratedBy
            }
          />
          <Dropdown
            label="Workflow Type" 
            options={[
              { label: "HR", value: "1" },
              { label: "OP", value: "2" },
              { label: "DEV", value: "3" },
            ]}
            value={formik.values.workflowType}
            onChange={(value) => formik.setFieldValue("workflowType", value)}
            error={formik.touched.workflowType && formik.errors.workflowType}
          />
          <CalendarComponent
            label="Workflow Due"
            value={formik.values.workflowDue}
            onChange={(value) => formik.setFieldValue("workflowDue", value)}
            error={formik.touched.workflowDue && formik.errors.workflowDue}
          />
          <Dropdown
            label="Frequency"
            options={[
              { label: "On Demand", value: "ondemand" },
              { label: "Recurrent", value: "recurrent" },
            ]}
            value={formik.values.frequency}
            onChange={(value) => formik.setFieldValue("frequency", value)}
            error={formik.touched.frequency && formik.errors.frequency}
          />
          {formik.values.frequency === "recurrent" && (
            <TextField
              label="Interval"
              placeholder="e.g., 5 days"
              value={formik.values.interval}
              onChange={(value) => formik.setFieldValue("interval", value)}
              error={formik.touched.interval && formik.errors.interval}
            />
          )}
        </div>

        {formik.values.steps.map((step, index) => (
          <div key={index} className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4 p-4 bg-gray-100 rounded-md shadow-md relative">
            <TextField
              label={`Step ${index + 1} name`}
              value={step.name}
              onChange={(value) => handleStepChange(index, "name", value)}
              error={
                formik.touched.steps &&
                formik.touched.steps[index] &&
                formik.touched.steps[index].name &&
                formik.errors.steps &&
                formik.errors.steps[index] &&
                formik.errors.steps[index].name
              }
            />
            <Dropdown
              label="Step Owner"
              options={[
                { label: "Akshay", value: "1" },
                { label: "Vivek", value: "2" },
                { label: "Amit", value: "3" },
              ]}
              value={step.stepOwner}
              onChange={(value) => handleStepChange(index, "stepOwner", value)}
              error={
                formik.touched.steps &&
                formik.touched.steps[index] &&
                formik.touched.steps[index].stepOwner &&
                formik.errors.steps &&
                formik.errors.steps[index] &&
                formik.errors.steps[index].stepOwner
              }
            />
            <CalendarComponent
              label="Step Due Date"
              value={step.due_date}
              onChange={(value) => handleStepChange(index, "due_date", value)}
              error={
                formik.touched.steps &&
                formik.touched.steps[index] &&
                formik.touched.steps[index].due_date &&
                formik.errors.steps &&
                formik.errors.steps[index] &&
                formik.errors.steps[index].due_date
              }
            />

            <AddButton
              onClick={() => handleAddButtonClick("precondition", index)}
              added={step.preconditions && step.preconditions.stepId}
            />
            <div className="col-span-1 sm:col-span-2 lg:col-span-3">
              <SelectButton
                onClick={(value) => handleSelectButtonClick(value, index)}
                addedTask={step.task && Object.keys(step.task).length > 0}
                addedProject={step.project && Object.keys(step.project).length > 0}
                addedEmail={step.email && Object.keys(step.email).length > 0}
              />
            </div>

            <button
              className="absolute top-2 right-2 text-rgb(6 11 49)-600  text-2xl"
              onClick={() => handleDeleteStep(index)}
            >
              <MdDeleteForever className="w-6 h-7" />
            </button>
          </div>
        ))}
        
        <div className="flex justify-center my-4">
          <div
            className="flex justify-center items-center gap-1 py-1 bg-button-color text-sm text-center rounded cursor-pointer w-32 hover:bg-button-secondary hover:text-button-primary"
            onClick={handleAddStep}
          >
            <IoMdAdd />
            Add Step
          </div>
        </div>

        <div className="flex justify-end">
          <button
            className="bg-button-secondary text-white text-sm px-4 py-2 rounded cursor-pointer hover:bg-button-secondary-hover hover:text-button-secondary"
            onClick={formik.handleSubmit}
            type="button"
          >
            Submit
          </button>
        </div>
      </div>
      </div>

      {isDrawerOpen && (
        <Drawer isOpen={isDrawerOpen} onClose={handleCloseDrawer}>
          {drawerContent}
        </Drawer>
      )}
    <ToastContainer />
    </div>
  );
};

export default CreateWorkflow;