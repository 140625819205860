import React, { useEffect, useRef,useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

const NavDropdown = ({ initialValue, items, isOpen, onToggle, onClose }) => {
  const [value, setValue] = useState(initialValue);
  const dropdownRef = useRef(null);

  const handleItemClick = (newValue) => {
    setValue(newValue);
    onClose(); // Close dropdown after selection
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="px-2 cursor-pointer flex items-center text-sm text-gray-700"
        onClick={onToggle}
      >
        {value}
        <IoMdArrowDropdown
          className={`ml-1 transition-transform transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {items.map((item) => (
              <div
                key={item}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm"
                onClick={() => handleItemClick(item)}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavDropdown;
