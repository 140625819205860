import React, { useState, useEffect } from "react";
import NavDropdown from "../components/NavDropdown";
import Card from "../components/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { GoWorkflow } from "react-icons/go";

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : process.env.REACT_APP_API_BASE_URL;

const Home = () => {
  const navigate = useNavigate();
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const location = useLocation();

  // Fetch workflows from the API
  const fetchWorkflows = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/workflows`);
      const data = await response.json();
      setCardsData(data);
    } catch (error) {
      console.error("Failed to fetch workflows:", error);
    }
  };

  // Re-fetch workflows when the page is loaded or navigated to
  useEffect(() => {
    fetchWorkflows();
  }, [location]);

  // Toggle the dropdown menu for each card
  const handleToggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  // Close the dropdown menu
  const handleCloseDropdown = () => {
    setOpenDropdownId(null);
  };

  // Handle deletion of a workflow card
  const handleDeleteWorkflow = (deletedWorkflowId) => {
    setCardsData((prevData) =>
        prevData.filter((card) => card.workflowId !== deletedWorkflowId)
    );
};


  const dropdownsData = [
    {
      id: 1,
      initialValue: "Workflow",
      items: ["Workflow", "Template"],
    },
    {
      id: 2,
      initialValue: "All",
      items: ["All", "Operations", "Delivery", "HR"],
    },
    {
      id: 3,
      initialValue: "inProgress",
      items: ["inProgress", "Scheduled", "Completed", "Closed"],
    },
  ];

  return (
    <div className="bg-main-bg">
      <div className="sticky top-1 bg-main-bg px-2 pt-2 z-10">
        <div className="flex justify-between w-full h-8 bg-white rounded-md items-center px-3">
          <div className="flex gap-10">
            {dropdownsData.map((dropdown) => (
              <div key={dropdown.id}>
                <NavDropdown
                  initialValue={dropdown.initialValue}
                  items={dropdown.items}
                  isOpen={openDropdownId === dropdown.id}
                  onToggle={() => handleToggleDropdown(dropdown.id)}
                  onClose={handleCloseDropdown}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-between items-center pt-4 px-3">
          <div className="font-medium text-xl">Workflow</div>
          <div
            className="flex items-center h-full bg-button-secondary text-white text-sm px-4 py-2 rounded-md cursor-pointer hover:bg-button-secondary-hover hover:text-button-secondary shadow-lg hover:shadow-2xl"
            onClick={() => navigate("/createworkflow")}
          >
            <GoWorkflow className="mr-2" />
            Create Workflow
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        {cardsData.map((card) => (
          <div key={card.workflowId} className="w-1/3 p-6">
            <Card
              title={card.workflowName}
              content={card.currentStep}
              workflowOwner={card.workflowOwner}
              collaboratedBy={card.collaboratedBy}
              dueDate={card.dueDate}
              progress={card.progress}
              task={card.task}
              currentStepOwner={card.currentStepOwner}
              workflowId={card.workflowId}
              frequency={card.frequency}
              interval={card.interval}
              onDelete={handleDeleteWorkflow}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
