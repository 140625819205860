import React from "react";

const TextField = ({ label, value, onChange, error }) => {
  const handleChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  return (
    <div className="flex flex-col px-6 py-1 w-full">
      <label htmlFor="textfield" className="mb-1 text-sm font-light uppercase">
        {label}
      </label>
      <input
        type="text"
        id="textfield"
        value={value} // Set the input value
        onChange={handleChange} // Handle input change
        className={`px-3 py-1 border rounded-sm focus:outline-none focus:ring-1 focus:border-blue-500 text-sm ${
          error ? "border-red-500" : ""
        }`}
      />
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
};

export default TextField;
