import React from "react";
import { IoMdAdd } from "react-icons/io";

const AddButton = ({ value, onClick, added }) => {
  return (
    <div className="flex flex-col px-6 py-1 w-full">
      <label htmlFor="textfield" className="mb-1 text-sm font-light uppercase">
        pre condition
      </label>
      <div
        className={`flex items-center justify-start w-auto text-sm px-3 py-1  rounded-sm cursor-pointer   ${
          added
            ? " bg-green-700 text-white "
            : "bg-white text-yellow-500 hover:text-green-700"
        }`}
        onClick={() => onClick(value)}
      >
        <IoMdAdd className="mr-2" />
        Add {value}
      </div>
    </div>
  );
};

export default AddButton;
